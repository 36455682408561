import Avatar from "../components/main/Avatar"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Footer from "../components/footer/Footer"
import Header from "../components/header/Header"
import Row from "react-bootstrap/Row"
import TarsildInfo from "../components/main/TarsildInfo"

function Main() {
  return (
    <>
      <Header />
      <Container>
        <Row className="mt-2">
          <Col className="mt-5" md={3} xs={12}>
            <Avatar />
          </Col>
          <Col className="mt-5" md={9} xs={12}>
            <TarsildInfo />
          </Col>
        </Row>
        {/* <Footer /> */}
      </Container>
    </>
  )
}

export default Main
