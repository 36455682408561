import Card from "react-bootstrap/Card"
import Icofont from "react-icofont"
import ListGroup from "react-bootstrap/ListGroup"

function Avatar() {
  return (
    <Card className="border border-0">
      <Card.Body>
        <img
          src="https://res.cloudinary.com/tarsild/image/upload/v1672327766/tarsild/logo/resources/avatar_gxhebt.png"
          className="img-fluid"
        />
      </Card.Body>
      <ListGroup variant="flush">
        <ListGroup.Item>
          <a
            href="https://github.com/sponsors/tarsil/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none fw-bolder"
          >
            <img
              src="https://github.githubassets.com/favicons/favicon.png"
              className="me-2 icon-sm"
            />
            tarsil
          </a>
        </ListGroup.Item>
        <ListGroup.Item>
          <Icofont icon="email" className="me-2" />
          <a
            href="mailTo:tiago.silva@dymmond.com"
            rel="noopener noreferrer"
            className="text-decoration-none fw-bolder"
          >
            Get in Touch
          </a>
        </ListGroup.Item>
        <ListGroup.Item>
          <Icofont icon="linkedin" className="me-2" />
          <a
            href="https://www.linkedin.com/in/tarsil/"
            rel="noopener noreferrer"
            className="text-decoration-none fw-bolder"
          >
            LinkedIn
          </a>
          {/* https://cdn-static-1.medium.com/_/fp/icons/Medium-Avatar-500x500.svg */}
        </ListGroup.Item>
        <ListGroup.Item>
          <a
            href="https://tarsil.medium.com/"
            rel="noopener noreferrer"
            className="text-decoration-none fw-bolder"
          >
            <img
              src="https://cdn-static-1.medium.com/_/fp/icons/Medium-Avatar-500x500.svg"
              className="me-2 icon-sm"
            />
            Medium
          </a>
        </ListGroup.Item>
        <ListGroup.Item>
          <a
            href="https://dev.to/tarsil"
            rel="noopener noreferrer"
            className="text-decoration-none fw-bolder"
          >
            <img
              src="https://res.cloudinary.com/practicaldev/image/fetch/s--gDM0_LTS--/c_limit,f_png,fl_progressive,q_80,w_180/https://practicaldev-herokuapp-com.freetls.fastly.net/assets/devlogo-pwa-512.png"
              className="me-2 icon-sm"
            />
            Dev.to
          </a>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  )
}

export default Avatar
