function Footer() {
  const getDate = () => {
    const date = new Date()
    return date.getFullYear()
  }

  return (
    <>
      <footer className="small">© {getDate()} All rights reserved</footer>
    </>
  )
}

export default Footer
