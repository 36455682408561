import { Route, BrowserRouter as Router, Routes } from "react-router-dom"

import Main from "./pages/Main"
import Projects from "./pages/Projects"
import { appRoutes } from "./constants"

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path={appRoutes.MAIN} element={<Main />}></Route>
          <Route path={appRoutes.PROJECTS} element={<Projects />}></Route>
        </Routes>
      </Router>
    </div>
  )
}

export default App
