import Container from "react-bootstrap/Container"
import { Link } from "react-router-dom"
import Navbar from "react-bootstrap/Navbar"
import { appRoutes } from "../../constants"

function Header() {
  return (
    <Navbar expand="lg" className="p-2 bg-primary shadow">
      <Container>
        <Navbar.Brand>
          <Link to={appRoutes.MAIN}>
            <img
              className="logo"
              src="https://res.cloudinary.com/tarsild/image/upload/v1672327459/tarsild/logo/tarsild/tarsild-white_mdfv9i.png"
            />
          </Link>
        </Navbar.Brand>
      </Container>
    </Navbar>
  )
}

export default Header
