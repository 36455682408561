import Icofont from "react-icofont"

function Sponsorship() {
  return (
    <div className="text-center">
      <a
        className="btn btn-primary border border-1 text-white align-items-center"
        target="_top"
        role="button"
      >
        <Icofont icon="heart me-2" />
        Sponsor
      </a>
    </div>
  )
}

export default Sponsorship
