import Card from "react-bootstrap/Card"
import { Link } from "react-router-dom"
import Sponsorship from "../sponsorship/Sponsor"
import { appRoutes } from "../../constants"

function TarsildInfo() {
  return (
    <Card className="border border-0">
      <Card.Body>
        <Card.Title as="h2">Welcome to my boring page 👋</Card.Title>
        <Card.Text>
          <p>
            Before starting, let me apologise in advanced if you landed in my
            boring page, sometimes happens but since you are already here why
            not telling you a bit more about me?
          </p>
          <p>
            I'm{" "}
            <a
              href="https://github.com/sponsors/tarsil/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none fw-bolder"
            >
              tarsild
            </a>{" "}
            (Tiago Silva) and the author of{" "}
            <a
              href="https://esmerald.dymmond.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none fw-bolder"
            >
              Esmerald
            </a>
            ,{" "}
            <a
              href="https://asyncz.tarsild.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none fw-bolder"
            >
              Asyncz
            </a>{" "}
            <a
              href="https://saffier.tarsild.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none fw-bolder"
            >
              Saffier
            </a>{" "}
            and a lot of open source projects out there.
          </p>
          <p>
            Currently living in The United Kingdom and originally from Portugal,
            I'm a Software Engineer, Architect and worked in a lot of industries
            out there.
          </p>
          <p>
            I've been building APIs, tools and software for a wide range of
            industries across the globe with different teams along with it.
          </p>
          <p>
            I am also the founder of{" "}
            <a
              href="https://asywww.dymmond.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none fw-bolder"
            >
              Dymmond
            </a>
            , a company designed to offer the best solutions for any business in
            every given area by leveraging the technology and deliver the best
            results for the organisation. E.g.: Data Engineering, Data Science,
            Data Analytics, Cloud Native, Blockchain... Just to name a few
            areas.
          </p>
          <p>
            When I created Esmerald and Asyncz was with the idea to be 100%
            free, open source and community driven so you can rest assured this
            will always happen 😁.
          </p>
          <p className="h5 mt-4 mb-4">
            You probably want to know about{" "}
            <Link to={appRoutes.PROJECTS} className="h5 text-decoration-none">
              my projects and open source.
            </Link>
          </p>
          <p>
            Esmerald, Asyncz and the upcoming projects take a lot of my time. I
            am also helping some small teams with Esmerald. If you would like to
            have my help as a <strong>consultant</strong>, feel always free to{" "}
            <strong>get in touch with me at any given time 📞📧</strong>
          </p>
          <p>
            If my open source material, projects or related{" "}
            <strong>helped you, your product and/or company</strong> you can
            also sponsor me and my work 😁
          </p>
          <Sponsorship />
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default TarsildInfo
