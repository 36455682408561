import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Icofont from "react-icofont"
import { Link } from "react-router-dom"
import Row from "react-bootstrap/Row"
import Sponsorship from "../sponsorship/Sponsor"
import { appRoutes } from "../../constants"

function OpenSource() {
  return (
    <Card className="border border-0">
      <Card.Body>
        <Link className="text-decoration-none" to={appRoutes.MAIN}>
          <Icofont icon="arrow-left me-2" />
          Back to the boring page
        </Link>
        <Card.Title as="h2" className="mt-3">
          Open source projects 👩‍💻
        </Card.Title>
        <Card.Text>
          If my open source projects helped your company, product or team and
          you feel generous, you can always sponsor me!
          <Sponsorship />
          <div className="mt-3">
            <p>
              <a
                href="https://esmerald.dymmond.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                Esmerald
              </a>{" "}
              - Esmerald is modern, high performant, easy to learn, code, deploy
              and fit for every purpose with python 3.7+ using python type
              hinting and pydantic. <b>Elegant, modular and pluggable</b>.
            </p>
            <p>
              <a
                href="https://saffier.tarsild.io"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                Saffier
              </a>{" "}
              - An async python ORM on the top of SQLAlchemy core with familiar interfaces and native migration system.
            </p>
            <p>
              <a
                href="https://asyncz.tarsild.io"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                Asyncz
              </a>{" "}
              - Asyncz is a modern, fast, clean python scheduler with async and
              pydantic at its core.
            </p>
            <p>
              <a
                href="https://esmerald-sessions.dymmond.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                Esmerald Sessions
              </a>{" "}
              - An alternative SessionMiddleware for Esmerald with Pydantic, for
              those more complex sessions needed for your applications.
            </p>
            <p>
              <a
                href="https://www.github.com/dymmond/esmerald-scaffold"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                esmerald-scaffold
              </a>{" "}
              - A simple ready-to-use Esmerald scaffold for any starting
              project.
            </p>
            <p>
              <a
                href="https://www.github.com/dymmond/esmerald-scaffold"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                esmerald-tortoise-nginx-gunicorn-supervisor
              </a>{" "}
              - A simple ready-to-use Esmerald scaffold for any starting
              project.
            </p>
            <p>
              <a
                href="https://www.github.com/tarsil/django-tenants-url"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                django-tenants-url
              </a>{" "}
              - Multitenancy simplified to work with single URLs.
            </p>
            <p>
              <a
                href="https://www.github.com/tarsil/django-fast-utils"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                django-fast-utils
              </a>{" "}
              - Utilities for every single django project.
            </p>
            <p>
              <a
                href="https://www.github.com/tarsil/django-loguru"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                django-loguru
              </a>{" "}
              - A middleware to log the requests and responses using loguru.
            </p>
            <p>
              <a
                href="https://www.github.com/tarsil/django-messages-drf"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                django-messages-drf
              </a>{" "}
              - Simple messaging system for Django implementing Django Rest
              Framework.
            </p>
            <p>
              <a
                href="https://www.github.com/tarsil/django_model_audit"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                django_model_audit
              </a>{" "}
              - Mixins for auditing your django models.
            </p>
            <p>
              <a
                href="https://www.github.com/tarsil/python-web-extras"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                python-web-extras
              </a>{" "}
              - About Extra flavours, helpers and integrations for the most
              common python web frameworks.
            </p>
            <p>
              <a
                href="https://github.com/tarsil/cookiecutter-django"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                cookiecutter-django
              </a>{" "}
              - Cookiecutter for Django with all the settings for production,
              security, login and more with integration with Postgres, Docker
              and Dramatiq.
            </p>
            <p>
              <a
                href="https://github.com/tarsil/cookiecutter-fastapi"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                cookiecutter-fastapi
              </a>{" "}
              - A simple cookiecutter for FastAPI allowing to create quick and
              fast applications without the hurdle of initial configurations.
            </p>
            <p>
              <a
                href="https://github.com/tarsil/cookiecutter-flask"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                cookiecutter-flask
              </a>{" "}
              - A simple out of the box and flask micro-service using Flask-API,
              Marshmallow and flask-restx.
            </p>
            <p>
              <a
                href="https://github.com/tarsil/cookiecutter-angular"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                cookiecutter-angular
              </a>{" "}
              - A cookiecutter with a offered structure and with integration
              with bootstrap.
            </p>
            <p>
              <a
                href="https://github.com/tarsil/cookiecutter-react"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                cookiecutter-react
              </a>{" "}
              - A React cookiecutter for with the most simplistic structure with
              Boostrap 5.
            </p>
            <p>
              <a
                href="https://github.com/tarsil/cookiecutter-react-web3"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                cookiecutter-react
              </a>{" "}
              - React for web3.
            </p>
            <p>
              <a
                href="https://www.github.com/tarsil/polygonscan-python"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none fw-bolder"
              >
                polygonscan-python
              </a>{" "}
              - The Polygon Scan APIs for python based from the fork of
              bscscan-python.
            </p>
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default OpenSource
